import React from 'react';
import Hero from './sections/hero';
import Features from './sections/features';
import Testimonials from './sections/testimonial';
import Pricing from './sections/pricing';
import Company from './sections/company';

function App() {
  return (
    <div className="App">
      <Hero />
      <Features />
      <Testimonials />
      <Pricing />
      <Company />

    </div>
  );
}

export default App;
