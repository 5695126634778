const stats = [
    { id: 1, name: 'Decrease in production cost', value: '27%' },
    { id: 2, name: 'Increase in production volume', value: '40%' },
    { id: 3, name: 'Hours saved', value: 'Countless' },
]

export default function Testimonials() {
    return (
        <div className="sm:mx-10 bg-slate-900 rounded-lg text-white py-16 sm:py-16">
            <div className="mx-auto max-w-2xl lg:max-w-4xl">
                <img className="mx-auto h-12 bg-white rounded" src="AERL.webp" alt="" />
                <figure className="mt-10">
                    <blockquote className="text-center text-xl font-semibold leading-8  sm:text-2xl sm:leading-9">
                        <p>
                            “Optimize has been a game-changer for AERL, streamlining our manufacturing processes and providing valuable data-driven insights. Their platform has enhanced efficiency and productivity, making them an indispensable partner in our operations.”
                        </p>
                    </blockquote>
                    <figcaption className="mt-10">
                        <img
                            className="mx-auto h-10 w-10 rounded-full"
                            src="/peter-head.png"
                            alt=""
                        />
                        <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                            <div className="font-semibold">Peter Watkinson</div>
                            <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                                <circle cx={1} cy={1} r={1} />
                            </svg>
                            <div className="">CEO of AERL</div>
                        </div>
                    </figcaption>
                </figure>
                {/*Stats*/}
                <div className=" pt-20 sm:pt-20">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                            {stats.map((stat) => (
                                <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                                    <dt className="text-base leading-7">{stat.name}</dt>
                                    <dd className="order-first text-3xl font-semibold tracking-tight sm:text-5xl">
                                        {stat.value}
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>

            </div>
        </div>
    );
}
